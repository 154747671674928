import './App.css';
import Link from '../link/Link';
import HappyMac from '../../assets/icons/HappyMac.svg';
import ReactTyped from 'react-typed';
import Animated from './Animated';

function App() {
  return (
    <div className="App">
      <div className="App-header">
        <div className='header-data'>
          <Animated />
        </div>
        <div className="footer-in-first">
          <p className="footer-text">। {" "}
            <ReactTyped
              strings={["ॐ असतो मा सद्गमय ।", "तमसो मा ज्योतिर्गमय ।", "मृत्योर्मा अमृतं गमय ।", "ॐ शान्तिः शान्तिः शान्तिः ॥"]}
              typeSpeed={90}
              loop
              backSpeed={30}
              showCursor={false}
            /></p>
        </div>
      </div>
      <div className="user-info">
        <div className='only-on-mobile'>
          <p className="mobile-text">। {" "}
            <ReactTyped
              strings={["ॐ असतो मा सद्गमय ।", "तमसो मा ज्योतिर्गमय ।", "मृत्योर्मा अमृतं गमय ।", "ॐ शान्तिः शान्तिः शान्तिः ॥"]}
              typeSpeed={90}
              loop
              backSpeed={30}
              showCursor={false}
            /></p>
        </div>
        <img className="img-happy-mac" src={HappyMac} alt="HappyMac" />
        <p className="user-name">
          Gagan Malvi
        </p>
        <p className="user-subtitle">I'm a {" "}
          <ReactTyped
            strings={["platform engineer by day", "tinkernut and modder by night", "UI/UX designer", "tech enthusiast", "dreamer"]}
            typeSpeed={90}
            loop
            backSpeed={30}
            cursorChar='_'
            showCursor={true}
          />
        </p>
        <p></p>
        <div className="user-links">
          <Link link="/github" text="GitHub" />
          <Link link="/linkedin" text="LinkedIn" />
          <Link link="/xtwt" text="X" />
          <Link link="/tg" text="Telegram" />
          <Link link="mailto:malvigagan@gmail.com" text="Email" />
          <Link link="/services" text="Services" />
          <Link link="/resume.pdf" text="Resume" />
        </div>
        <p></p>
      </div>
    </div>
  );
}

export default App;
